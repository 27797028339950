import axios from 'axios'
const baseURL = process.env.VUE_APP_BACKEND_URL;
const axiosApi = axios.create({
  baseURL,
})


axiosApi.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export default axiosApi