<template>
  <navigation-pladcon />
  <router-view />
  <footer-page v-show="!showOnSpecificPath" />

  <transition name="modal-fade">
    <div v-if="$root.modalFormVisible.value">
      <modalhow-can-we-help-you
        @actionOk="$root.modalPerfecVisible.value = true"
        @actionClose="$root.modalFormVisible.value = false"
      />
    </div>
  </transition>

  <transition name="modal-fade">
    <div v-if="$root.modalPerfecVisible.value">
      <submitted-form @actionClose="$root.modalPerfecVisible.value = false" />
    </div>
  </transition>

  <chatPladcon @antionClose="chatVisible" />

  <div class="container_chat_icon cursor_pointer">
    <span v-if="token_countMessage_total_ref !== 0">{{
      token_countMessage_total_ref
    }}</span>
    <img
      @click="chatVisible(), hiddenToolty()"
      src="./assets/ArchivosDiseñoWeb/IconosPng/ChatIcono.png"
      alt="ChatIcono"
    />
    <div class="display_none_mobile" id="message_toolty">
      <span @click="hiddenToolty" class="icon-close"></span>
      <img src="./assets/message.png" alt="message" />
      <span class="paragraph">{{ $t("HowCanWeHelpYou") }}</span>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import FooterPage from "./pages/components/footerPage.vue";
import NavigationPladcon from "./pages/components/navigation/navigationPladcon.vue";
import { topMax } from "./utilities";
import { useRoute } from "vue-router";
import ModalhowCanWeHelpYou from "./pages/components/modalhowCanWeHelpYou.vue";
import SubmittedForm from "./pages/components/SubmittedForm.vue";
import useChat from "./pages/composables/useChat";
import chatPladcon from "./pages/components/chatPladcon.vue";

export default {
  components: {
    NavigationPladcon,
    FooterPage,
    ModalhowCanWeHelpYou,
    SubmittedForm,
    chatPladcon,
  },

  setup() {
    const visibleToolty = () => {
      if (!document.querySelector(".chat_visible")) {
        const messageChat = document.querySelector("#message_toolty");
        messageChat.classList.add("message_chat");
      }
    };
    const hiddenToolty = () => {
      const messageChat = document.querySelector("#message_toolty");
      messageChat.classList.remove("message_chat");
    };

    let {
      loandDatabase,
      countChatAdviser_2,
      token_countMessage_total_ref,
      chatVisible,
      getRandomId,
    } = useChat();

    const methodLoadChat = async () => {};

    if (!localStorage.getItem("token_chat")) {
      localStorage.setItem("token_chat", getRandomId());
    }
    loandDatabase(methodLoadChat);
    countChatAdviser_2();
    onMounted(() => {
      setTimeout(visibleToolty, 10000);
    });

    const route = useRoute();
    let showOnSpecificPath = ref(false);
    watch(
      () => route.path,
      (newPath) => {
        showOnSpecificPath.value = newPath === "/ContactUs"
        topMax();
      }
    );



    return {
      hiddenToolty,
      chatVisible,
      showOnSpecificPath,
      token_countMessage_total_ref,
    };
  },
};
</script>

<style>
</style>
