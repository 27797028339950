import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage.vue'),
  },
  {
    path: '/:pathMatch(.*)*', 
    redirect: { name: 'homePage' }, 
  },
  {
    path: '/aboutPage',
    name: 'aboutPage',
    component: () => import(/* webpackChunkName: "aboutPage" */ '@/pages/aboutPage.vue'),
  },
  {
    path: '/projectsPage',
    name: 'projectsPage',
    component: () => import(/* webpackChunkName: "projectsPage" */ '@/pages/projectsPage.vue'),
    children: [
      {
        path: 'urySecond',
        name: 'urySecond',
        component: () => import(/* webpackChunkName: "urySecond" */ '@/pages/projects/urySecond.vue'),
      },
      {
        path: 'wImportaciones',
        name: 'wImportaciones',
        component: () => import(/* webpackChunkName: "wImportaciones" */ '@/pages/projects/wImportaciones.vue'),
      },
      {
        path: 'mawikaApp',
        name: 'mawikaApp',
        component: () => import(/* webpackChunkName: "mawikaApp" */ '@/pages/projects/mawikaApp.vue'),
      },
      {
        path: 'gocargoPay',
        name: 'gocargoPay',
        component: () => import(/* webpackChunkName: "gocargoPay" */ '@/pages/projects/gocargoPay.vue'),
      },
    ]
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "ContactUs" */ '@/pages/ContactUs.vue'),
  },
  {
    path: '/servicesPage',
    name: 'servicesPage',
    component: () => import(/* webpackChunkName: "servicesPage" */ '@/pages/servicesPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router
