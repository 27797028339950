import { parameterApi } from "@/api/parameterApi"

export const loadChat = async ({ commit }) => {
    try {
        const { data } = await parameterApi.loadChat()
        const dataDB = await data
        const arrayChats = []
        for (let id in dataDB) {
            arrayChats.push(dataDB[id])
        }
        commit('loadchatUser', arrayChats)

    } catch (error) {
        console.log(error)
    }
}


export const setDatoschats = async (_, chat) => {
    try {
        await parameterApi.setDatoschats(chat)
    } catch (error) {
        console.log(error)
    }
}
export const setTotalmessague = async ({ commit }, totalmessague) => {
    commit('token_countMessage_total', totalmessague)
}