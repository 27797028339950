<template>
  <div class="content_chat">
    <header class="gap_2 display_grid">
      <div
        class="justify_content_space_between align_items_center display_flex"
      >
        <div class="gap_2_4 align_items_center display_flex">
          <img class="imgProfile" src="../../assets/manage.png" alt="" />
          <div>
            <p class="paragraph text_weight_bold">
              {{ $t("CommercialAdvisor") }}
            </p>
            <p class="paragraph">{{ $t("online_care") }}</p>
          </div>
        </div>
        <div @click="$emit('antionClose')" class="height-2 cursor_pointer">
          <span></span>
        </div>
      </div>
      <img class="width_100" src="../../assets/linea.png" alt="linea" />
    </header>
    <div class="chat">
      <div v-for="(item, index) in chats" :key="index">
        <div
          v-if="item.typeUser === 'user'"
          class="user justify_content_flex_end display_flex gap_1_3"
        >
          <div>
            <p class="paragraph">{{ item.message }}</p>
            <p class="paragraph font_size_1_2">{{ item.date }}</p>
          </div>
          <span class="icon-profile_user text_align_end font_size_3_8"></span>
        </div>
        <div v-else class="adviser display_flex gap_1_3">
          <img
            class="adviser_perfil imgProfile"
            src="../../assets/manage.png"
            alt=""
          />
          <div>
            <p class="paragraph">{{ item.message }}</p>
            <p class="paragraph font_size_1_2">{{ item.date }}</p>
          </div>
        </div>
      </div>
    </div>
    <form
      @submit.prevent="processForm"
      class="gap_2 display_grid"
      id="form_message"
    >
      <img class="width_100" src="../../assets/linea.png" alt="linea" />
      <div class="gap_1_3 display_flex">
        <input
          type="text"
          v-model="chat.message"
          name="message"
          id="message"
          class="input_massage"
          :placeholder="$t('message_here')"
        />
        <button
          id="button"
          type="submit"
          class="icon-SendMsg button_message"
          :disabled="block"
        ></button>
      </div>
    </form>
  </div>
</template>

<script>
import useChat from "../composables/useChat";
export default {
  emits: ["antionClose"],

  setup() {
    let { checkLoadChat, chats, chat, block, processForm } = useChat();

    return {
      checkLoadChat,
      chats,
      chat,
      block,
      processForm,
    };
  },

  methods: {
    chatVisibilityHiden() {
      const element = document.querySelector("#chat-oculto");
      element.classList.remove("chat-visible");
    },
  },
};
</script>

