
import { useStore } from 'vuex'
import { initializeApp } from "firebase/app";
import { getDatabase, ref as firebaseRef, onValue } from "firebase/database";
import { computed, ref as vueRef } from 'vue';
const useChat = () => {
    const store = useStore()
    const baseURL = process.env.VUE_APP_BACKEND_URL;

    let chat = vueRef({
        id: "",
        typeUser: "",
        idConversation: "",
        date: "",
        message: "",
        name: "",
    })

    const loadChat = async () => {
        await store.dispatch('pagues/loadChat')
    }

    const setDatoschats = async (chat) => {
        await store.dispatch('pagues/setDatoschats', chat)
    }
    const setTotalmessague = async (chat) => {
        await store.dispatch('pagues/setTotalmessague', chat)
    }

    const chats = computed(() => {
        return store.state.pagues.chats
    }
    );
    const token_countMessage_total_ref = computed(() => {
        return store.state.pagues.token_countMessage_total
    }
    );

    const block = computed(() => {
        return chat.value.message === ""
    })

    const loandDatabase = () => {
        const firebaseConfig = {
            apiKey: "AIzaSyBbxyt8hg4UhQmOYNP0iDiEBlaAcnd8nPU",
            authDomain: "pladcon-e4479.firebaseapp.com",
            databaseURL: baseURL,
            projectId: "pladcon-e4479",
            storageBucket: "pladcon-e4479.appspot.com",
            messagingSenderId: "661839503674",
            appId: "1:661839503674:web:8d048e91caa1d0e2897003",
            measurementId: "G-J2T4GJ7Y2D"
        };
        initializeApp(firebaseConfig);
        const database = getDatabase();
        const dataRef = firebaseRef(database, "chat");
        onValue(dataRef, async () => {
            await loadChat();
            countChatAdviser_1();
            countChatAdviserTotal();
            scrollbotton();
        });
    }

    const scrollbotton = () => {
        const scrollableElement = document.querySelector(".chat");
        if (scrollableElement) {
            const scrollHeight = scrollableElement.scrollHeight;
            scrollableElement.scrollTo({ top: scrollHeight, behavior: "smooth" });
        }
    }



    const processForm = async () => {
        const serviceID = 'default_service';
        const templateID = 'template_mmc4632';
        const formElement = document.getElementById("form_message");
        chat.value.idConversation = localStorage.getItem("token_chat");
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();

        let dateTimeString = now.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        dateTimeString = dateTimeString + ` ${day}-${month}-${year}`;

        chat.value.id = new Date().getTime();
        chat.value.typeUser = "user";
        chat.value.date = dateTimeString;
        localStorage.setItem("token_countMessage_2", 0);
        localStorage.setItem("token_countMessage_1", 0);
        await setDatoschats(chat.value);
        /* eslint-disable no-undef */
        await emailjs.sendForm(serviceID, templateID, formElement).then(
            () => {
            },
            (err) => {
                alert(JSON.stringify(err));
            }
        );
        chat.value = {
            id: "",
            typeUser: "",
            idConversation: "",
            date: "",
            message: "",
            name: "",
        }
    }


    const countChatAdviser_1 = () => {
        let countMessage = 0;
        const values = Object.values(chats.value);
        for (let i = values.length - 1; i > 0; i--) {
            const chatObj = values[i];
            if (chatObj.typeUser === "adviser") {
                countMessage++;
            } else {
                break;
            }
        }
        localStorage.setItem("token_countMessage_1", countMessage);
    }

    const messageTotal = () => {
        let token_countMessage_1 = parseInt(
            localStorage.getItem("token_countMessage_1")
        );
        let token_countMessage_2 = parseInt(
            localStorage.getItem("token_countMessage_2")
        );
        let token_countMessage_total =
            token_countMessage_1 - token_countMessage_2;
        localStorage.setItem(
            "token_countMessage_total",
            token_countMessage_total
        );
        setTotalmessague(parseInt(
            localStorage.getItem("token_countMessage_total")
        ))
    }

    const countChatAdviserTotal = () => {
        if (document.querySelector(".chat_visible")) {
            let token_countMessage_1 = parseInt(
                localStorage.getItem("token_countMessage_1")
            );
            localStorage.setItem("token_countMessage_2", token_countMessage_1);
        }
        messageTotal()
    }

    const getRandomId = () => {
        const timestamp = Date.now().toString();
        const uniqueId = timestamp;
        return uniqueId;
    }

    const countChatAdviser_2 = () => {
        if (!localStorage.getItem("token_countMessage_2")) {
            localStorage.setItem("token_countMessage_2", 0);
        }
    }


    const chatVisible = () => {
        let token_countMessage_1 = parseInt(
            localStorage.getItem("token_countMessage_1")
        );
        localStorage.setItem("token_countMessage_2", token_countMessage_1);
        messageTotal()
        const element = document.querySelector(".content_chat");
        if (element.classList.contains("chat_visible")) {
            element.classList.remove("chat_visible");
        } else {
            element.classList.add("chat_visible");
        }
    };
    return {
        loadChat,
        loandDatabase,
        countChatAdviser_1,
        chats,
        chat,
        block,
        chatVisible,
        countChatAdviser_2,
        token_countMessage_total_ref,
        processForm,
        scrollbotton,
        getRandomId
    }
}

export default useChat