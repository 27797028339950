<template>
  <div class="position_relative container_language">
    <p class="paragraph cursor_pointer font_size_2">
      {{ $i18n.locale === "es" ? "ES" : "EN" }}
    </p>
    <div>
      <p
        :class="{ text_weight_bold: $i18n.locale === 'es' }"
        @click="$i18n.locale = 'es'"
        class="paragraph cursor_pointer color_white font_size_2"
      >
        ES
      </p>
      <p
        :class="{ text_weight_bold: $i18n.locale === 'en' }"
        @click="$i18n.locale = 'en'"
        class="paragraph cursor_pointer color_white font_size_2"
      >
        EN
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.font_size_2{
  font-size: 2rem !important;
}

@media(max-width: 1250px){
  .container_language{
    margin-left: -8rem;
  }
}
</style>