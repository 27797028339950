export default () => ({
    chats: [],
    token_countMessage_total: 0,
    chat: {
        id: "",
        typeUser: '',
        idConversation: "",
        date: "",
        message: "",
    },
})