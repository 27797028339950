<template>
  <div
    class="z_index_100 width_100 align_items_center display_flex container_navigation"
    id="navigation"
  >
    <div
      class="align_items_center width_100 justify_content_space_between gap_4_5 display_flex"
    >
      <img
        @click="$router.push({ name: 'homePage' })"
        class="logo_pladcon cursor_pointer"
        src="../../../assets/ArchivosDiseñoWeb/Logos/PladconLogoOscuro.png"
        alt=""
      />

      <div
        class="display_flex display_none_mobile align_items_center height_fit_content gap_4_5"
      >
        <CustomLink v-for="link in links" :key="link.to" :link="link" />
        <a
          href="https://calendly.com/pladcon-contacto/30min"
          target="_blank"
          class="button"
        >
          {{ $t("exploratory") }}
        </a>
        <LanguajeChange />
      </div>
      <LanguajeChange class="display_none_desk" />
      <div
        id="link_navigation"
        class="display_flex navigation_link align_items_center height_fit_content gap_4_5"
      >
        <div
          :class="{ last_childnavigation_link: index + 1 == 5 }"
          v-for="(link, index) in links"
          :key="link.to"
        >
          <CustomLink @click="navigationVisible" :link="link" />
        </div>
        <a
          href="https://calendly.com/pladcon-contacto/30min"
          target="_blank"
          class="button button_mobile"
        >
          {{ $t("exploratory") }}
        </a>
        <span class="icon_select_menu"></span>
      </div>

      <span
        id="menu_dropdown"
        @click="navigationVisible"
        class="icon-menu display_none_desk"
      ></span>
    </div>
    <div class="navigation_line">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onBeforeUnmount, onMounted } from "vue";
import LanguajeChange from "../languajeChange.vue";
export default {
  data() {
    return {
      links: [
        {
          to: "homePage",
          name: "home",
        },
        {
          to: "aboutPage",
          name: "about",
        },
        {
          to: "servicesPage",
          name: "services",
        },
        {
          to: "projectsPage",
          name: "projects",
        },
        {
          to: "ContactUs",
          name: "contact",
        },
      ],
    };
  },

  setup() {
    const navigationVisible = () => {
      const elemetNavigation = document.querySelector("#link_navigation");
      if (elemetNavigation.classList.contains("navigation_visible")) {
        elemetNavigation.classList.remove("navigation_visible");
      } else {
        elemetNavigation.classList.add("navigation_visible");
      }
    };

    window.addEventListener("scroll", () => {
      const element = document.getElementById("navigation");
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 150) {
        element.classList.add("navigation_fixed");
      } else {
        element.classList.remove("navigation_fixed");
      }
    });

    onMounted(() => {
      window.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", handleClickOutside);
    });

    const handleClickOutside = (event) => {
      const divSelectMenu = document.getElementById("link_navigation");
      const iconMenu = document.getElementById("menu_dropdown");
      if (
        divSelectMenu &&
        !divSelectMenu.contains(event.target) &&
        iconMenu &&
        !iconMenu.contains(event.target)
      ) {
        if (divSelectMenu.classList.contains("navigation_visible")) {
          divSelectMenu.classList.remove("navigation_visible");
        }
      }
    };

    return {
      navigationVisible,
    };
  },

  components: {
    CustomLink: defineAsyncComponent(() => import("./customLink.vue")),
    LanguajeChange,
  },
};
</script>

<style scoped>
.navigation_line {
  position: absolute;
  display: flex;
  bottom: 0;
  gap: 2.4rem;
  transition: all 0.5s;
  left: 0;
  right: 0;
}

.container_navigation .navigation_line {
  visibility: hidden;
  opacity: 0;
}
.navigation_fixed .navigation_line {
  visibility: visible;
  opacity: 1;
}

.navigation_line span {
  background: var(--primary);
  height: 0.1rem;
}
.navigation_line span:nth-child(1) {
  width: 85%;
}
.navigation_line span:nth-child(2) {
  width: 9%;
}
.navigation_line span:nth-child(3) {
  width: 9%;
}
</style>
