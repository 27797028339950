export default {
  es: {
    home: "Inicio",
    about: "Nosotros",
    services: "Servicios",
    projects: "Proyectos",
    contact: "Contáctanos",
    exploratory: "Llamada exploratoria",

    // home
    yourAllyInDesig: "Desarrollo de software a la medida para potenciar tu negocio",

    DesignUIUX: "Diseño (UI/UX)",
    WeUseDesignAsAMeans: "Utilizamos el diseño como medio en nuestro enfoque de desarrollo de aplicaciones, con el fin de generar experiencias digitales innovadoras.",
    Developmentcode: "Desarrollo (código)",
    AnotherAspectOfOurApproach: "Otro matiz de nuestro enfoque reside en el desarrollo de softwares escalables que posibilitan mejoras incrementales en su producto.",
    SupportAndMaintenance: "Soporte y mtto",
    ProporcionamosSoportemantenimiento: "Proporcionamos soporte y mantenimiento a los productos que creamos, asegurando su estabilidad y funcionamiento permanentemente.",


    WeHelpYouTakeYourBusiness: "Te ayudamos a llevar tu negocio a otro nivel tecnológico",
    WeAddressAllTheEssentialAspects: "Atendemos todos los aspectos indispensables para la creación de productos digitales innovadores y de excelencia. Desde la formulación de estrategias hasta el diseño de productos, incluyendo tanto los servicios front-end como los back-end, le brindamos respaldo integral.",
    OurCodeIsCraftedUnderExtremely: "Nuestro código se forja bajo estándares sumamente elevados y llevamos a cabo pruebas de manera constante, resultando en productos de máxima calidad que son sencillos de mantener.",
    OurDevelopmentCapabilitiesAre: "Nuestras capacidades de desarrollo son sobresalientes, pero trascendemos más allá de ello; nos transformamos en un auténtico socio estratégico en tu negocio.",

    WeDesignAndorDevelopMobile: "Diseñamos y/o desarrollamos aplicaciones web y móviles para tu negocio",
    MobileAppDesign: "Diseño de apps móviles",
    MobileAppDevelopment: "Desarrollo de apps móviles",
    WebsiteDesign: "Diseño de sitios web",
    WebsiteDevelopment: "Desarrollo de sitios web",
    EcommerceSolutions: "Soluciones de comercio electrónico",
    QualityAssurance: "Seguridad de calidad",
    ContinuityForExistingProducts: "Continuidad de productos ya existentes",
    MinimumViableProductMVP: "Producto mínimo viable (MVP)",

    PlanDesignAndDevelop: "Planifica, diseña y desarrolla tus productos digitales con nosotros",
    ElevateYourBusinessToNew: "Eleva tu negocio a nuevas alturas al hacer de nosotros tu aliado en tecnología. En nuestro compromiso contigo, abarcamos todos los aspectos de tu solución tecnológica, desde la concepción inicial hasta la implementación y el continuo mantenimiento. Permítenos encargarnos de cada fase crucial, incluyendo la meticulosa planificación, el diseño innovador, el desarrollo preciso y el sostenimiento a largo plazo de tu producto.",
    WithOurExpertiseAtYourDisposal: "Con nuestra experiencia a tu disposición, puedes concentrarte en tus objetivos mientras te aseguramos una solución tecnológica sólida y vanguardista. Juntos, alcanzaremos un nivel superior de éxito en el mundo digital.",
    IWishToContactYou: "Deseo contactarlos",

    SomeCompaniesThatTrustInUs: "Algunas compañías que confían en nosotros",

    Christian: "“En Pladcon hemos encontrado más que un aliado estratégico en tecnología para nuestra compañía; nuestra tranquilidad reposa en ellos”. ",
    Christian2: "CEO de Maga Studios",
    Jose: "“Pladcon: para mí es sinónimo de profesionalismo, organización y cumplimiento, poner mis proyectos en sus manos son garantía de un resultado positivo”.",
    Jose2: "CEO de GoCargo",
    Dairo: "“Desde la concepción de una idea hasta su implementación final, nos esforzamos por superar las expectativas en cada etapa del proceso. Nuestro equipo altamente capacitado de desarrolladores, diseñadores y expertos en tecnología trabaja incansablemente para crear productos que no solo sean funcionales, sino también estéticamente atractivos y fáciles de usar”.",
    Dairo2: "CEO de Pladcon",
    Dairo3: "Mensaje de nuestro CEO",

    //footer
    CallUsOrWriteToUs: "Llámanos o escríbenos",
    Worksectors: "Sectores de trabajo",
    Service: "Servicio ",
    Landfreighttransportation: "Transporte de carga terrestre",
    Health: "Salud ",
    Government: "Gobierno ",
    RealEstate: "Inmobiliario ",
    HeavyMining: "Minería pesada",
    Advertising: "Publicidad",
    Location: "Ubicación",
    ContactUs: "Contacto",
    Allrightsreserved: "Pladcon S.A.S.",
    Allrightsreserved2: "Todos los derechos reservados - Políticas de privacidad",

    //about
    WeAreYourTechnological: "Somos tu aliado tecnológico que apoya a tu negocio",
    OurFocusIsCentered: "Nuestro enfoque se centra en proyectos de aplicaciones web y móviles, pero trascendemos los límites de una agencia convencional.",
    ThroughaStrategicPartnership: "Mediante una asociación estratégica en el desarrollo y la aplicación de un método ágil a medida, llevamos a su empresa y sus usuarios a la vanguardia del proceso de evolución.",
    WePutOurHeartIntoit: "Le ponemos el corazón",
    WhenItComesToWebAndMobile: "Cuando hablamos de aplicaciones web y móviles, la creación de experiencias digitales innovadoras y la preservación de la calidad adquieren una importancia crucial. No obstante, se requiere mucho más para asegurar el florecimiento de su empresa. Nuestro enfoque como socios estratégicos contribuye a garantizar no solo un producto de calidad, sino también resultados empresariales notables.",
    ForThisReasonWeCollaborate: " Es por esta razón que colaboramos estrechamente junto a ti para tomar decisiones estratégicas óptimas que beneficien de forma positiva a tu empresa. Tanto si se trata de una pequeña empresa emergente o de una corporación de gran envergadura, tu negocio es nuestra prioridad.",

    //PROYECT
    SomeCompletedProjects: "Algunos proyectos realizados",
    InvestingInRealEstateHasNeve: "Invertir en bienes raíces nunca había sido tan asequible desde la llegada de URY al mercado, con la compra y venta fraccionada de unidades habitacionales.",
    AnAppArrivesInColombia: "Llega a Colombia una app que integra la cadena logística de transporte terrestre en Colombia y financia a las empresas de transporte para que operen con tranquilidad.",
    WImportsExpandsItsPossibilities: "W Importaciones amplía sus posibilidades de; aumentar sus ventas en LATAM y atraer nuevos fabricantes exportadores de repuestos de motos del continente asiático.",
    MawikaRevolutionizesTheService: "Mawika revoluciona el mercado de servicio con su nueva app, a través de la cual ofrece servicios para el hogar, pagos en línea por servicio recibido, entre otras opciones.",

    //ury
    MakingRealEstate: "Haciendo de la inversión en bienes raíces más asequible",
    Category: "Categoría",
    HighTechnology: "Alta tecnología",
    Devices: "Dispositivos",
    MobileAndDesktop: "Móvil y escritorio",
    Completed: "Realizado",
    andCodeDevelopment: "UI/UX y desarrollo (código)",
    URYSecondHomeIsASpecialized: "URY Second Home es un aplicativo web especializado en segunda vivienda o de uso turístico e inversión, con énfasis en el manejo de la propiedad compartida, que permite:",
    StreamliningTheMarketing: " - La agilización en la comercialización de inmuebles destinados a segunda vivienda o con uso turístico e inversión.",
    EnablingThoseWith: "- A quienes, teniendo la capacidad, quieren diversificar su destino.",
    ExpandingAccessToSecond: "- Amplía el acceso a inversión en segunda vivienda para personas que teniendo el deseo no alcanzan con su capacidad económica para hacerlo.",
    BalancingTheInvestment: "- Equilibrar el porcentaje de inversión con el disfrute o uso esperado de la propiedad. ",
    GuillermoLuisUribeOchoa: "Guillermo Luis Uribe Ochoa, CEO de URY Second Home, es quien ha venido a Pladcon con esta gran idea que posibilita a más personas con menos capacidades económicas para invertir en bienes raíces. Hoy, a través de este aplicativo web es posible llevar a cabo inversiones en bienes raíces de bajas cantidades no solo en Colombia sino también en los Estados Unidos.",
    IAmInTheProcessOfBuilding: "“Estoy en el proceso de construcción de un negocio soportado por un aplicativo que estamos desarrollando con Pladcon. Determinamos tres fases, y ya recibí la primera. Quiero destacar el profesionalismo del equipo, donde resaltó su método de trabajo, entendimiento de los requerimientos, aportes para el mejoramiento de la solución y cumplimiento de calendarios. Es un gusto trabajar con ellos”. ",
    CEOdeURYSecondHome: "CEO de URY Second Home",
    ChallengeAndSolution: "Desafío y solución",
    InTheDevelopmentOfDigitalProducts: "En el desarrollo de productos digitales, enfrentamos retos únicos que requieren soluciones creativas. Un caso destacado fue el desafío de crear una app web para propiedades compartidas en segundas viviendas y uso turístico. Abordar esto significaba superar obstáculos tecnológicos y conceptuales.",
    OurSolutionShowcased: "Nuestra solución demostró cómo la colaboración y la ingeniería precisa pueden impulsar la innovación. La plataforma no solo cumplió con las expectativas, sino que también rompió barreras convencionales.",
    AMajorHurdleWasStreamlining: "Un gran obstáculo fue agilizar la comercialización de propiedades compartidas. Creamos una interfaz atractiva que permitió a inversores explorar eficientemente diversas opciones. Esto mejoró la experiencia del usuario y aceleró decisiones e inversiones.",
    SharedPropertyIsAlsoAbout: "La propiedad compartida también se trata de acceso equitativo a segundas viviendas. Diseñamos un sistema que asignaba la propiedad de manera proporcional, considerando las aspiraciones de cada inversor. Garantizamos equidad en inversión y tiempo en la propiedad. ",
    WeAlsoTackledDestinationDiversification: " Abordamos también la diversificación de destinos. A través de la interfaz interactiva",
    WeAlsoTackledDestinationDiversification2: ", presentamos opciones emocionantes en diferentes lugares, enriqueciendo carteras y experiencias.",
    WhatBeganAsAnIdeaTurnedInto: " Lo que empezó como una idea se convirtió en una colaboración apasionante. La app no solo cumplió, sino que transformó el mercado de propiedades compartidas. Este proyecto subraya la importancia de creatividad, innovación y colaboración en productos digitales. Contribuimos a una visión única y valiosa.",

    DoYouNeedTechnicalAssistanceWithYourIdea: "¿Necesitas ayuda tecnológica con tu idea?",
    AtPladconYouWontJustFindSupport: "En Pladcon no solo encontrarás apoyo para encender tus ideas, sino que seremos más que eso, seremos tu aliado, quien junto ti, construiremos el producto digital adecuado con un fuerte enfoque en el usuario. Dicho enfoque, llevado a cabo bajo el modelo de investigación de diseño que utilizamos para lograrlo, el modelo Design Thinking Process. ",
    ContactUsShareYourIdea: "Contáctanos, cuéntanos tu idea y encendámosla juntos.",

    //gocargo
    IntegratingTheLogistics: "Integrando la cadena logística de transporte de carga terrestre en Colombia",
    GoCargoPayIsAWebApplicatio: "GoCargo Pay es un aplicativo web que integra la cadena logística de transporte terrestre en Colombia. Su eje principal es la financiación de las operaciones de medianas empresas de transporte; el aplicativo integra módulos de financiamiento (colocación, cartera, rendimientos financieros, entre otros), de contratación y asignación de conductores, lo que asegura la trazabilidad del viaje. El aplicativo web es de manejo interno para GoCargo Pay, con una ‘visual’ para las empresas de transporte, para que tengan acceso a la información de interés con respecto al financiamiento, contrataciones de los viajes y seguimiento del mismo para monitorear la operación.",
    TheProjectScopeAlso: "El alcance del proyecto también abarca interacción con conductores de camiones de transporte de carga terrestre, quienes a través de la aplicación móvil, la cual descargan, reciben indicaciones precisas de los viajes; la app ofrece la posibilidad de una comunicación clara entre los interesados, y un sistema de seguridad que ofrece una transparente trazabilidad del viaje (cargue y descargue).",
    PladconHasEnabledUsToSuccessfully: "“Pladcon nos ha permitido llevar a cabo con éxito los proyectos que hemos entregado en sus manos y esto obedece en gran medida a la aplicación de metodologías de desarrollo, que van desde la toma de requerimiento, elaboración de flujos de trabajos, prototipos basados en diseño UI/UX donde visualmente se ajusta todo lo necesario, para que al pasar a desarrollo esté definido claramente el entregable; esto ha permitido la entrega eficiente del producto final”.",
    TheChallengeOfExecutingTheRequirement: "El reto para llevar a cabo el requerimiento de esta gran idea que beneficia al sector de transporte de carga terrestre en Colombia fue importante y muy emocionante.",
    WeWerePresentedWithARequirement: " Nos encontramos con un requerimiento que integra como base un sistema de financiamiento para las operaciones de medianas empresas de transporte. El cliente ha solicitado una trazabilidad total del proceso para llevar control del financiamiento y evitar malos usos del recurso, ya que en experiencias pasadas se han enfrentado a ello.",
    AsASolutionWeConceive: "Por lo que hemos ideado como solución hacer una sinergia entre financiamiento y operación; brindándole a ",
    AsASolutionWeConceive2: "la posibilidad no solo de la colocación del recurso monetario sino de su distribución en cada paso de las operaciones de las empresas de transporte.",
    ThroughTheWebApplication: "Es el mismo GoCargo Pay quien se encarga, a través del aplicativo web, de la contratación con las empresas, asignación de transportistas y demás funciones que le permiten tener completo control del recurso financiado.",
    AfterStrenuousEffortsToCreate: "Después de un arduo trabajo para llevar a cabo una experiencia de usuario intuitiva y también un desarrollo de código fuente con una estructura sólida que pueda soportar la magnitud de la plataforma; y por supuesto, un sistema robusto de seguridad puesto que estamos tratando no solo de importantes sumas de dinero sino de información de gran delicadeza, funciones de la operatividad como: contratación con empresas de transporte, asignación de transportistas, pagos de anticipos y saldos, trazabilidad del cargue y descargue de los vehículo y constante comunicación con los conductores.",
    ThereIsNoWayForResources: "No hay manera de que los recursos se utilicen de manera inadecuada ya que el aplicativo ofrece una sinergia perfecta del financiamiento y la operación de los viajes.",

    // w importaciones
    AnEfficientWayToAttractNewClients: "Una manera eficiente de atraer nuevos clientes",
    Website: "Sitio web",
    WImportacionesIsACompany: "W Importaciones es una compañía que importa repuestos de motocicletas para Colombia y LATAM. Esta empresa tiene repuestos de marca propia, los cuales son fabricados en China; cuenta con productos económicos y premium. Tienen poco más de 8 años de operación y su crecimiento ha sido importante pero ha llegado la hora de expandirse y para ello se han aliado con Pladcon, no solo para desarrollar un sitio web más, sino uno que, a partir de estudios del consumidor (de su target), que al entrar a dicho sitio, sientan tal atracción que se logre el objetivo principal de la creación de la web, que los leads se conviertan finalmente en ventas.",
    TheWebsiteWasDesigned: "El sitio web fue diseñado de tal manera que pudiera tener una evolución al punto de convertirse en comercio electrónico para llevar a cabo ventas al por mayor dirigidas a almacenes de repuestos de motos. Por supuesto, es Pladcon quien está encargado de llevar a cabo dicho evolutivo y así apoyar al crecimiento de nuestro aliado W Importaciones.",
    PladconHasWnabledUsToSuccessfully: "“Pladcon ha sido un aliado estratégico para nuestro proyecto. Su enfoque innovador en el desarrollo, que abarca desde la captura de requisitos hasta la creación de flujos de trabajo y prototipos basados en diseño UI/UX, ha sido esencial. La aplicación de estas metodologías nos ha proporcionado una representación visual coherente de todos los elementos esenciales. Esto, a su vez, ha garantizado una definición precisa de los entregables durante la fase de desarrollo, lo que ha contribuido significativamente a la eficiencia en la entrega del producto final.”",
    TheChallengeWeFacedWas: "El desafía al cual nos encontramos fue, una vez atraído los leads, que estos, se encontraran con un sitio de tal atractivo para ellos que llevaran a cabo la acción objetivo, contactar a W Importaciones para que finalmente se conviertan en clientes.",
    UponReceivingTheRequirement: "Al recibir el requerimiento de nuestro aliado ",
    UponReceivingTheRequirement2: "entendimos que no podíamos partir del diseño sino de un modelo de investigación, el cual se le denomina Design Thinking Process, para poder crear un sitio que lograra, una vez atraído los leads, el objetivo de esta empresa.",
    WeCarriedOutAnIterative: "Llevamos a cabo un proceso iterativo de investigación bajo el modelo de diseño anteriormente mencionado que nos condujo a tomar algunos pasos requeridos para lograr con éxito el motivo por el cual nuestro aliado nos había contactado.",
    TheFirstTheseStepsWas: "El primero de estos pasos ha sido ‘Empathise’ (‘Empatizarse’ en español); donde nos enfrascamos en el público objetivo de W Importaciones he hicimos investigaciones a través de encuestas que nos arrojaron datos sumamente importantes, que para resumir en pocas palabras, nos contaron cuáles son los problemas a los cuales este público se enfrenta a la hora de encontrar un proveedor del tipo de productos que ofrece W Importaciones.",
    AfterGraspingTheProblem: "Luego de entender el problema, continuamos al siguiente paso, ‘Define’ (‘Definir’ en español); a través del cual definimos distintas maneras de solucionar los desafíos, los cuales fueron filtrados por los encuestados, quienes nos dieron datos muy valiosos de cuál era la mejor manera de resolverlos. Seguimos con ‘Ideate’ (‘Ideación’, en español); donde definimos ideas de cómo resolver el proyecto con los datos anteriores obtenidos. Luego de todo este importante proceso de investigación pasamos a diseñar el ‘Prototype’ (‘Prototipo’ en español); aquí, se define a través de wireframes (bocetos) la estructura comunicacional del producto para luego llevarla a diseño. Este diseño pasa por el último paso de este proceso de investigación de productos digitales el cual es ‘Test’ (‘Prueba’ en español); donde, valga la redundancia, se testea con personas seleccionadas (clientes actuales y potenciales clientes) para llevar a cabo un ‘Guerrilla Testing’. Este último nos ofrece un feedback de los aciertos y desaciertos para así terminar con la versión final del prototipo y pasar posteriormente al desarrollo el cual fue construido en el lenguaje de programación de Java.",
    WImportacionessCEO: "CEO de W Importaciones",
    wilson:"“Con Pladcon, encontramos más que un aliado en tecnología; es la clave de nuestra tranquilidad empresarial. Su enfoque innovador y dedicación nos han impulsado hacia niveles de eficiencia sorprendentes”",

    // mawika
    ServicesForYourHome: "Servicios para tu hogar y negocio al alcance de tu mano",
    MawikaIsACompanyThatProvides: "Mawika es una compañía que presta servicios para los hogares, negocios (empresas) u oficinas, con más de 10 años de experiencia en el área de instalación, reparación y mantenimiento de plomería, electricidad (cableado y electrodomésticos), pintura, enchape, cerrajería, instalación de pisos, corrección de humedad, fumigación, desinfección, limpieza, modulares, entre otros.",
    MawikaHasDecidedToFurther: "La empresa Mawika ha tomado la decisión de encender aun más su negocio, por lo que se ha aliado con Pladcon para llevar su compañía a otras esferas. Hoy día cuenta con una app que está disponible para móviles con sistema operativo ",
    MawikaHasDecidedToFurther2: "; también está disponible por medio de la ",
    MawikaHasDecidedToFurther4: ", simplemente ingresando a su página web.",
    ForPladconItHasBeen: "Ha sido, para Pladcon, más que un placer haber participado de este grandioso proyecto.",
    MawikaHasUndertakenASubstantial: "Mawika ha asumido un desafío de considerable envergadura al trasladar su reconocido conjunto de servicios, consolidados durante más de una década, al ámbito tecnológico. El objetivo primordial radicaba en la automatización de los procesos de pedido de servicios, así como en la programación de las visitas técnicas a domicilios y establecimientos comerciales para satisfacer las necesidades de su clientela. En esa nueva etapa, se buscaba implementar una plataforma vanguardista que facilitare transacciones seguras y eficientes.",
    TheScopeOfThisChallenge: "El alcance de este desafío no se limitó únicamente a lo mencionado. Un elemento esencial consistía en otorgar a los técnicos una interfaz que les permitiera recibir las solicitudes directamente desde los clientes. La premisa subyacente es que el técnico disponible y en proximidad óptima asumiera la responsabilidad de ejecutar el servicio requerido.",
    ThisVisionWasRealizedThrough: "La ejecución de esta visión se materializó mediante la creación de una aplicación especialmente diseñada para los clientes. A través de esta herramienta, los usuarios pueden solicitar servicios específicos, seleccionar fechas y horarios convenientes para la visita, adjuntar imágenes y comentarios que proporcionen una comprensión precisa de los trabajos a realizar. Los técnicos, por su parte, tienen la capacidad de evaluar visualmente las solicitudes y efectuar inspecciones virtuales, lo que les permite planificar y llevar consigo las herramientas adecuadas, independientemente de su ubicación física. Elementos cruciales, como un sistema de pagos robusto, la opción de cancelar servicios, así como un sistema de comunicación tipo chat, han sido incorporados con la finalidad de garantizar un servicio cómodo y completo.",
    ConsistentWithThisComprehensiveApproach: "En congruencia con este enfoque integral, se ha concebido una aplicación específica para los técnicos. A través de esta interfaz, los profesionales pueden gestionar las solicitudes entrantes, administrar sus agendas y acudir directamente a los lugares designados para llevar a cabo las tareas requeridas con eficacia y precisión.",
    ItsImportantToHighlight: "Es importante destacar que la aplicación se encuentra disponible en múltiples plataformas, incluyendo ",
    ItsImportantToHighlight2: "así como en el ",
    ItsImportantToHighlight3: " sitio web oficial ",
    ItsImportantToHighlight4: "de Mawika, garantizando una accesibilidad universal que se adapte a las preferencias individuales de cada usuario.",
    and: "y",
    ThanksToOurCollaborationwithPladcon: "“Gracias a la colaboración con Pladcon, conseguimos materializar y concretar nuestra visión empresarial, posibilitando así un crecimiento exponencial. Su participación ha sido clave en transformar nuestras ideas en realidades tangibles, lo que nos ha permitido expandirnos de manera significativa.”",
    CEOMawika: "CEO de Mawika",
    // modal
    HowCanWeAssistYou: "¿Cómo podemos ayudarte?",
    Name: "Nombre *",
    Company: "Empresa *",
    Email: "Correo *",
    CellPhone: "Teléfono/cel *",
    TellUsAboutYourIdea: "Háblanos sobre tu idea",
    ByClicking: "Al presionar clic en ‘enviar’ aceptas las",
    ByClicking2: " políticas de tratamiento de datos ",
    ByClicking3: " de la compañía Pladcon S.A.S.",
    Send: "Enviar",

    PladconYourDesign: "Pladcon: tu aliado de ‘diseño y desarrollo’ de aplicaciones",
    Sending: "Enviando ....",
    Sent2: "Enviado",

    HowCanWeHelpYou: "¿En qué podemos ayudarte?",
    online_care: "Atención en línea",
    CommercialAdvisor: "Asesor comercial (Pladcon)",
    message_here: "Mensaje aquí...",

    //servicio
    SoftwareDevelopment: "Desarrollo de software a la medida",
    expertiseSoftwareDevelopment: "Nos destacamos por nuestra especialización en el desarrollo de software, centrándonos en entornos de programación de vanguardia como Java – Spring Boot, así como en frameworks de JavaScript de alta demanda como Vue.js, Angular y React. Nuestra pericia abarca tanto bases de datos relacionales como no relacionales.",
    CustomizedSolutions: "Soluciones Personalizadas para Cada Cliente",
    approachDevelopment: "En el corazón de nuestro enfoque se encuentra el desarrollo de productos y proyectos adaptados a las necesidades únicas de cada cliente. Aplicamos metodologías agiles, respaldadas por los más rigurosos procedimientos y controles de nuestro sistema de gestión de calidad. Buscamos no solo la eficacia, sino también la máxima utilidad para garantizar resultados excepcionales.",
    SpecializationAreas: "Áreas de Especialización",
    BespokeDevelopment: "Desarrollo a medida",
    customizedProductsMeet: "Diseñamos nuestros productos personalizados para satisfacer necesidades específicas que los software estándar no abordan. Al estudiar a fondo las necesidades de nuestros clientes, ofrecemos herramientas adaptadas que les permiten destacar en su sector, enfrentándose de manera efectiva a los cambios económicos y tecnológicos. ",
    ApplicationDevelopment: "Desarrollo de aplicaciones",
    functionalitiesDevelopments: "Integramos funcionalidades y desarrollos en aplicaciones que aportan un valor añadido a los clientes, como comercio electrónico y desarrollos web de última generación. Buscamos la modularidad y escalabilidad de los productos, destacando la utilización de componentes diseñados.",
    CustomDesignUIUX: "Diseño Personalizado (UI/UX)",
    WeSpecializeInCraftingExceptionalUser: "Nos especializamos en la creación de experiencias de usuario excepcionales a través de un diseño personalizado y centrado en el usuario. Nuestro equipo colabora estrechamente con los clientes para desarrollar interfaces intuitivas y atractivas, incorporando innovación para elevar la usabilidad y estética de las soluciones tecnológicas.",
  },
  en: {
    home: "Home",
    about: "About us",
    services: "Services",
    projects: "Projects",
    contact: "Contact us",
    exploratory: "Exploratory call",

    // home
    yourAllyInDesig: "Custom software development to enhance your business.",

    DesignUIUX: "Design (UI/UX)",
    WeUseDesignAsAMeans: "We use design as a means in our approach to application development, in order to create innovative digital experiences.",
    Developmentcode: "Development (code)",
    AnotherAspectOfOurApproach: " Another aspect of our approach lies in creating scalable applications that enable incremental improvements to your product.",
    SupportAndMaintenance: "Support and maintenance:",
    ProporcionamosSoportemantenimiento: "We provide support and maintenance for the products we create, ensuring their stability and continuous operation.",


    WeHelpYouTakeYourBusiness: "We help you take your business to another technological level",
    WeAddressAllTheEssentialAspects: "We address all the essential aspects for creating innovative and excellent digital products. From strategy formulation to product design, including both front-end and back-end services, we provide comprehensive support.",
    OurCodeIsCraftedUnderExtremely: "Our code is crafted under extremely high standards, and we conduct continuous testing, resulting in top-quality products that are easy to maintain.",
    OurDevelopmentCapabilitiesAre: "Our development capabilities are outstanding, but we go beyond that; we become a true strategic partner in your business.",

    WeDesignAndorDevelopMobile: "We design and/or develop web and mobile applications for your business",
    MobileAppDesign: "Mobile app design",
    MobileAppDevelopment: "Mobile app development",
    WebsiteDesign: "Website design",
    WebsiteDevelopment: "Website development",
    EcommerceSolutions: "E-commerce solutions",
    QualityAssurance: "Quality assurance",
    ContinuityForExistingProducts: "Continuity for existing products",
    MinimumViableProductMVP: "Minimum viable product (MVP)",

    PlanDesignAndDevelop: "Plan, design, and develop your digital products with us",
    ElevateYourBusinessToNew: "Elevate your business to new heights by making us your technology ally. In our commitment to you, we cover all aspects of your technological solution, from initial conception to implementation and ongoing maintenance. Allow us to take care of each crucial phase, including meticulous planning, innovative design, precise development, and long-term sustainability of your product.",
    WithOurExpertiseAtYourDisposal: "With our expertise at your disposal, you can focus on your goals while we ensure a robust and cutting-edge technological solution. Together, we will achieve a higher level of success in the digital world.",
    IWishToContactYou: "I wish to contact you",

    SomeCompaniesThatTrustInUs: "Some companies that trust in us",

    Christian: "“At Pladcon, we have found more than a strategic technology partner for our company; our peace of mind rests with them”.",
    Christian2: "Maga Studios CEO",
    Jose: "“Pladcon: to me, it's synonymous with professionalism, organization, and fulfillment; placing my projects in their hands is a guarantee of a positive outcome”.",
    Jose2: "GoCargo CEO",
    Dairo: "“From the conception of an idea to its final implementation, we strive to exceed expectations at every stage of the process. Our highly skilled team of developers, designers, and technology experts work tirelessly to create products that are not only functional but also aesthetically appealing and user-friendly”.",
    Dairo2: "Pladcon's CEO",
    Dairo3: "Message from our CEO",

    //footer
    CallUsOrWriteToUs: "Call us or write to us",
    Worksectors: "Work sectors",
    Service: "Service",
    Landfreighttransportation: "Land freight transportation",
    Health: "Health",
    Government: "Government",
    RealEstate: "Real estate",
    HeavyMining: "Heavy mining",
    Advertising: "Advertising",
    Location: "Location",
    ContactUs: "Contact us",
    Allrightsreserved: "Pladcon S.A.S.",
    Allrightsreserved2: "All rights reserved - Privacy policies",

    //about
    WeAreYourTechnological: "We are your technological ally supporting your business",
    OurFocusIsCentered: "Our focus is centered on web and mobile application projects, but we transcend the boundaries of a conventional agency.",
    ThroughaStrategicPartnership: "Through a strategic partnership in development and the implementation of a tailored agile methodology, we lead your company and its users to the forefront of the evolutionary process.",
    WePutOurHeartIntoit: "We put our heart into it",
    WhenItComesToWebAndMobile: "When it comes to web and mobile applications, the creation of innovative digital experiences and the preservation of quality take on crucial importance. However, much more is required to ensure the flourishing of your company. Our approach as strategic partners contributes to guarantee not only a quality product but also remarkable business outcomes.",
    ForThisReasonWeCollaborate: "For this reason, we collaborate closely with you to make optimal strategic decisions that positively benefit your company. Whether it's a small emerging business or a large corporation, your business is our priority.",

    //PROYECT
    SomeCompletedProjects: "Some completed projects",
    InvestingInRealEstateHasNeve: "Investing in real estate has never been so affordable since the arrival of URY in the market, with fractional buying and selling of residential units.",
    AnAppArrivesInColombia: "An app arrives in Colombia that integrates the logistics chain of land transportation and provides financing to transportation companies to operate with ease.",
    WImportsExpandsItsPossibilities: "W Imports expands its possibilities to increase sales in LATAM and attract new exporting manufacturers of motorcycle parts from the Asian continent.",
    MawikaRevolutionizesTheService: "Mawika revolutionizes the service market with its new app, through which it offers home services, online payments for received services, and other options.",

    //ury
    MakingRealEstate: "Making real estate investment more affordable.",
    Category: "Category",
    HighTechnology: "High technology",
    Devices: "Devices",
    MobileAndDesktop: "Mobile and desktop",
    Completed: "Completed",
    andCodeDevelopment: " UI/UX and code development",
    URYSecondHomeIsASpecialized: "URY Second Home is a specialized web application for second homes or vacation and investment properties, with a focus on shared property management, which allows:",
    StreamliningTheMarketing: "- Streamlining the marketing of properties intended for second homes or vacation and investment purposes.",
    EnablingThoseWith: "- Enabling those with the means to diversify their destinations.",
    ExpandingAccessToSecond: "- Expanding access to second home investment for individuals whose desires exceed their financial capacity to do so.",
    BalancingTheInvestment: "- Balancing the investment percentage with the anticipated enjoyment or use of the property.",
    GuillermoLuisUribeOchoa: "Guillermo Luis Uribe Ochoa, CEO of URY Second Home, has brought this great idea to Pladcon, making it possible for people with limited financial means to invest in real estate. Today, through this web application, it's possible to carry out real estate investments in small amounts not only in Colombia but also in the United States.",
    IAmInTheProcessOfBuilding: "“I am in the process of building a business supported by an application that we are developing with Pladcon. We have identified three phases, and I have already received the first one. I want to highlight the professionalism of the team, where their working method stands out, their understanding of the requirements, contributions to the improvement of the solution, and adherence to schedules. It is a pleasure to work with them.”",
    CEOdeURYSecondHome: "URY Second Home CEO ",

    ChallengeAndSolution: "Challenge and Solution",
    InTheDevelopmentOfDigitalProducts: "In the development of digital products, we face unique challenges that require creative solutions. One standout case was the challenge of creating a web app for shared properties in second homes and vacation use. Addressing this meant overcoming technological and conceptual obstacles.",
    OurSolutionShowcased: "Our solution showcased how collaboration and precise engineering can drive innovation. The platform not only met expectations but also shattered conventional barriers.",
    AMajorHurdleWasStreamlining: "A major hurdle was streamlining the marketing of shared properties. We crafted an appealing interface that allowed investors to efficiently explore various options. This enhanced the user experience and expedited decisions and investments.",
    SharedPropertyIsAlsoAbout: "Shared property is also about equitable access to second homes. We designed a system that allocated ownership proportionally, taking each investor's aspirations into account. We ensured fairness in both investment and time spent in the property.",
    WeAlsoTackledDestinationDiversification: "We also tackled destination diversification. Through the interactive ",
    WeAlsoTackledDestinationDiversification2: " interface, we presented exciting choices in different locations, enriching portfolios and experiences.",
    WhatBeganAsAnIdeaTurnedInto: "What began as an idea turned into an exciting collaboration. The app not only delivered but also transformed the shared property market. This project underscores the importance of creativity, innovation, and collaboration in digital products. We contributed to a unique and valuable vision.",

    DoYouNeedTechnicalAssistanceWithYourIdea: "Do you need technical assistance with your idea?",
    AtPladconYouWontJustFindSupport: "At Pladcon, you won't just find support to ignite your ideas, but we'll be more than that – we'll be your ally, working alongside you to build the right digital product with a strong user-centered approach. This approach is carried out through the design research model we use to achieve it, the Design Thinking Process model.",
    ContactUsShareYourIdea: "Contact us, share your idea, and let's ignite it together.",

    //gocargo
    IntegratingTheLogistics: "Integrating the logistics chain of land freight transportation in Colombia",
    GoCargoPayIsAWebApplicatio: "GoCargo Pay is a web application that integrates the logistics chain of land freight transportation in Colombia. Its main focus is financing operations for medium-sized transportation companies. The application integrates financing modules (placement, portfolio, financial returns, among others), as well as driver hiring and assignment, ensuring journey traceability. The web application is for internal use at GoCargo Pay, with a user interface for transportation companies to access information related to financing, trip contracts, and real-time tracking for operation monitoring.",
    TheProjectScopeAlso: "The project scope also includes interaction with truck drivers involved in land freight transportation. They receive precise trip instructions through a mobile application they download. This app enables clear communication between stakeholders and offers a security system that provides transparent journey traceability (loading and unloading).",
    PladconHasEnabledUsToSuccessfully: "“Pladcon has enabled us to successfully carry out the projects we have entrusted to their hands, and this is largely attributed to the application of development methodologies. These methodologies span from requirement gathering and workflow design to prototypes based on UI/UX design, visually aligning all necessary aspects. This ensures that when transitioning to development, the deliverable is clearly defined. This approach has facilitated the efficient delivery of the final product.”",
    TheChallengeOfExecutingTheRequirement: "The challenge of executing the requirement for this significant idea that benefits the land freight transportation sector in Colombia was substantial and truly exciting.",
    WeWerePresentedWithARequirement: "We were presented with a requirement that forms the foundation of a financing system for medium-sized transportation companies. The client requested complete traceability of the process to maintain control over the financing and prevent misuse of resources, given past experiences.",
    AsASolutionWeConceive: "As a solution, we conceived a synergy between financing and operations; providing ",
    AsASolutionWeConceive2: " with the ability not only to provide the monetary resource but also to distribute it at every step of the transportation companies' operations.",
    ThroughTheWebApplication: "Through the web application, GoCargo Pay handles contracting with companies, assigning carriers, and other functions that enable them to have complete control over the financed resource.",
    AfterStrenuousEffortsToCreate: "After strenuous efforts to create an intuitive user experience and to develop source code with a robust structure capable of supporting the platform's magnitude, and of course, a strong security system, since we're dealing not only with significant sums of money but also with sensitive information. This includes operational functions like contracting with transportation companies, carrier assignments, advance and balance payments, load and unload traceability, and constant communication with drivers.",
    ThereIsNoWayForResources: "There is no way for resources to be used inappropriately since the application offers a seamless synergy between financing and trip operations.",


    // w importaciones
    AnEfficientWayToAttractNewClients: "An efficient way to attract new clients",
    Website: "Website",
    WImportacionesIsACompany: "W Importaciones is a company that imports motorcycle spare parts for Colombia and LATAM. This company has its own brand of spare parts, which are manufactured in China; they offer both budget and premium products. With over 8 years of operation, their growth has been significant. However, the time has come for expansion, and for that purpose, they have partnered with Pladcon. This partnership aims to develop not just another website, but one that, based on consumer studies (target audience), creates such attraction upon entry that it achieves the main goal of the website's creation: turning leads into sales.",
    TheWebsiteWasDesigned: "The website was designed in a way that it could evolve to become an e-commerce platform for wholesale sales targeting motorcycle spare parts stores. Of course, it's Pladcon's responsibility to carry out this evolution and thereby support the growth of our partner, W Importaciones.",
    PladconHasWnabledUsToSuccessfully: "“Pladcon has been a strategic ally for our project. Their innovative approach to development, ranging from capturing requirements to creating workflows and prototypes based on UI/UX design, has been essential. The implementation of these methodologies has provided us with a cohesive visual representation of all essential elements. This, in turn, has ensured a precise definition of deliverables during the development phase, significantly contributing to the efficiency in delivering the final product.”",
    TheChallengeWeFacedWas: "The challenge we faced was, after attracting the leads, ensuring that these leads would encounter a site so appealing to them that they would take the desired action: contacting W Importaciones and ultimately becoming customers.",
    UponReceivingTheRequirement: "Upon receiving the requirement from our partner, ",
    UponReceivingTheRequirement2: " we understood that we couldn't start with design alone, but rather with a research model called Design Thinking Process. This approach allowed us to create a site that would achieve the objective of converting these leads into customers once attracted.",
    WeCarriedOutAnIterative: "We carried out an iterative research process under the aforementioned design model, which guided us through the necessary steps to successfully address our partner's objective.",
    TheFirstTheseStepsWas: "The first of these steps was 'Empathize'; we deeply engaged with W Importaciones' target audience and conducted surveys that provided crucial data. To summarize briefly, these surveys revealed the challenges this audience faces when searching for a supplier offering products similar to those of W Importaciones.",
    AfterGraspingTheProblem: "After grasping the problem, we moved on to the next step, 'Define'. Through this phase, we defined various ways to solve the challenges. These potential solutions were filtered by the survey respondents, who provided valuable insights into the most effective ways to address the issues. We then entered the 'Ideate' phase, where we generated ideas based on the previous data collected. Following this extensive research process, we transitioned to designing the 'Prototype' phase. Here, we defined the communicational structure of the product through wireframes, setting the foundation for the design. This design went through the final step of the research process for digital products, 'Test'. We conducted 'Guerrilla Testing', involving selected individuals (current and potential customers) to provide feedback on successes and areas needing improvement. This process led us to the final version of the prototype, paving the way for development, which was constructed using the Java programming language.",
    WImportacionessCEO: "W Importaciones CEO",
    wilson:"“With Pladcon, we've found more than a technology ally; it's the key to our business peace of mind. Their innovative approach and dedication have propelled us to remarkable levels of efficiency. Thanks to them, we face technological challenges with confidence and success”", 

    // mawika
    ServicesForYourHome: "Services for your home and business at your fingertips",
    MawikaIsACompanyThatProvides: "Mawika is a company that provides services for homes, businesses, and offices, with over 10 years of experience in installation, repair, and maintenance of plumbing, electricity (wiring and appliances), painting, tiling, locksmith services, flooring installation, moisture correction, pest control, disinfection, cleaning, modular installations, among others.",
    MawikaHasDecidedToFurther: "Mawika has decided to further enhance its business and has partnered with Pladcon to take their company to new heights. Today, they have an app available for both",
    MawikaHasDecidedToFurther2: " mobile devices, as well as through the ",
    MawikaHasDecidedToFurther4: " by accessing their website.",
    ForPladconItHasBeen: "For Pladcon, it has been more than a pleasure to be part of this fantastic project",
    MawikaHasUndertakenASubstantial: "Mawika has undertaken a substantial challenge by transitioning their well-established array of services, consolidated over more than a decade, into the technological realm. The primary objective was to automate service ordering processes and schedule technical visits to homes and commercial establishments to meet their customer needs. In this new phase, the goal was to implement an innovative platform that facilitates secure and efficient transactions.",
    TheScopeOfThisChallenge: "The scope of this challenge went beyond the aforementioned aspects. An essential element was to provide technicians with an interface enabling them to receive service requests directly from customers. The underlying premise was that the available technician in optimal proximity would take responsibility for executing the required service.",
    ThisVisionWasRealizedThrough: "This vision was realized through the creation of a specially designed application for customers. Through this tool, users can request specific services, select convenient dates and times for visits, attach images and comments providing a precise understanding of the work to be done. Technicians, on the other hand, can visually assess requests and conduct virtual inspections, allowing them to plan and carry appropriate tools regardless of their physical location. Crucial elements like a robust payment system, service cancellation option, and chat communication have been incorporated to ensure comprehensive and convenient service.",
    ConsistentWithThisComprehensiveApproach: "Consistent with this comprehensive approach, a specific application for technicians has been conceived. Through this interface, professionals can manage incoming requests, organize their schedules, and directly go to designated locations to effectively and precisely carry out required tasks.",
    ItsImportantToHighlight: "It's important to highlight that the application is available across multiple platforms, including ",
    ItsImportantToHighlight2: " as well as the ",
    ItsImportantToHighlight3: " official Mawika website",
    ItsImportantToHighlight4: ", ensuring universal accessibility tailored to each user's individual preferences.",
    and: "and",
    ThanksToOurCollaborationwithPladcon: "“Thanks to our collaboration with Pladcon, we managed to materialize and concretize our business vision, enabling exponential growth. Their involvement has been crucial in transforming our ideas into tangible realities, allowing us to expand significantly.”",
    CEOMawika: "Mawika CEO",
    // modal
    HowCanWeAssistYou: "How can we assist you?",
    Name: "Name *",
    Company: "Company *",
    Email: "Email *",
    CellPhone: "Cell phone *",
    TellUsAboutYourIdea: "Tell us about your idea",
    ByClicking: "By clicking 'send,' you accept the data ",
    ByClicking2: " processing policies ",
    ByClicking3: " of Pladcon S.A.S. company.",
    Send: "Send",

    PladconYourDesign: "Pladcon: Your 'Design and Development' Ally for Applications",
    Sending: "Sending ....",
    Sent2: "Sent",
    HowCanWeHelpYou: "How can we help you?",
    online_care: "Online support",
    CommercialAdvisor: "Commercial advisor (Pladcon)",
    message_here: "Message here...",

    //servicio
    SoftwareDevelopment: "Custom software development.",
    expertiseSoftwareDevelopment: "We stand out for our expertise in software development, focusing on cutting-edge programming environments such as Java – Spring Boot, as well as highly sought-after JavaScript frameworks like Vue.js, Angular, and React. Our proficiency extends to both relational and non-relational databases.",
    CustomizedSolutions: "Customized Solutions for Each Client",
    approachDevelopment: "Customized Solutions for Each Client At the core of our approach lies the development of products and projects tailored to the unique needs of each client. We employ agile methodologies, supported by the most rigorous procedures and controls in our quality management system. We aim for not only efficiency but also maximum utility to ensure exceptional results.",
    SpecializationAreas: "Specialization Areas",
    BespokeDevelopment: "Bespoke Development",
    customizedProductsMeet: "We design our customized products to meet specific needs that standard software does not address. By thoroughly studying our clients' requirements, we offer tailored tools that enable them to excel in their industry, effectively facing economic and technological changes.",
    ApplicationDevelopment: "Application Development",
    functionalitiesDevelopments: "We integrate functionalities and developments into applications that provide added value to clients, such as e-commerce and state-of-the-art web developments. We seek modularity and scalability in products, emphasizing the use of carefully designed components.",
    CustomDesignUIUX: "Custom Design (UI/UX)",
    WeSpecializeInCraftingExceptionalUser: "We specialize in crafting exceptional user experiences through personalized and user-centric design. Our team collaborates closely with clients to develop intuitive and engaging interfaces, incorporating innovation to enhance the usability and aesthetics of technological solutions.",
  }
}